<template>
	<div class="playlistCreate">

		<h1 v-if="playlistData" class="text-center">„{{ playlistData.name }}“</h1>
		<p class="text-center">{{ playlistItems }} Übungen</p>

		<div v-if="requiresEquipment" class="notification"><b>Hinweis:</b> Eine oder mehrere Übungen deines Workouts benötigen zusätzliches Equipment.</div>

		<!-- Shuffle Mode -->
		<div class="list list--background">

            <label class="list__item">
                <div class="list__item__content">
                    <div class="list__item__label">
                        Zufällige Wiedergabe
                    </div>
                </div>
                <div class="toggle">
                    <input v-model="shuffle" type="checkbox" checked>
                    <span class="toggle__button"></span>
                </div>
            </label>

        </div>

        <!-- Liste der Übungen -->
		<div v-if="playlistData" class="list list--background">

			<draggable v-model="draggableItems" :options="{disabled: shuffle}" handle=".handle">
				<transition-group>
				<div v-for="exercise in draggableItems" :key="exercise.id" class="list__item">
					<div :class="shuffle ? 'handle' : 'handle handle--active'"></div>
					<div class="list__item__content">
						<div  class="list__item__label">
							{{ exercise.title }}
						</div>
						<img v-if="exercise.requires_equipment" src="@/assets/ic_equipment.svg" class="equipment-icon">
					</div>

					<img @click="deletePlaylistItem(exercise)" src="@/assets/ic_16_delete.svg" class="delete-icon">
				</div>
				</transition-group>
			</draggable>

		</div>

		<v-btn :disabled="playlistItems == 0" class="button button--primary" @click="finishPlaylistCreation">Workout speichern</v-btn>
	</div>
</template>

<script>
import draggable from 'vuedraggable'
import {analytics} from '@/firebase/index.js';

export default {
	name: 'PlaylistCreateName',
	components: { draggable },
	mounted() {
		this.init();
	},
	data() {
		return {
			playlistData: null,
			draggableItems: null,
			shuffle: false,
		}
	},
	methods: {
		init() {
			this.playlistData = this.$store.getters.getTemporaryPlaylist;
			this.draggableItems = [...this.playlistData.items];
			this.shuffle = this.playlistData.shuffle || false;
		},
		finishPlaylistCreation() {
			this.playlistData.shuffle = this.shuffle;
			let idx = 1;
			this.draggableItems.forEach( it => it.sortOrder = idx++ );
			this.$store.dispatch('updateTemporaryPlaylist', {
				shuffle: this.shuffle,
				isFavourite: true,
				items: this.draggableItems,
			}).then( () => {
				this.$store.dispatch('saveUserPlaylist', this.playlistData);
				/* clear playlist after save */
				this.$store.dispatch('initTemporaryPlaylist', {});
			});
			analytics.logEvent('create_user_playlist');
			this.$router.push("/playlists");
		},
		deletePlaylistItem(it) {
			this.draggableItems = this.draggableItems.filter(ex => ex.id != it.id);
		}
	},
	computed: {
		playlistItems() {
			if (this.draggableItems) {
				return this.draggableItems.length;
			}
			return 0;
		},
		requiresEquipment() {
			if (this.draggableItems) {
				let foundItems = this.draggableItems.filter(ex => ex.requires_equipment);
				if (foundItems.length > 0) return true;
			}
			return false;
		}
	}
}
</script>

	<style lang="scss">
	/* TODO: REMOVE CODE IF SCSS FROM PLAYLISTCREATENAME EFFECTS GLOBALLY */
	.playlistCreate {
		display: flex;
		flex-direction: column;
		min-height: 100%;

		.equipment-icon {
			margin: -7px 0px -7px 7px;
		}

		h1 {
			margin-top: 31px;
			margin-bottom: 18px;
		}

		p {
			margin-bottom: 41px;
		}

		.button {
			margin-top: auto;
		}

		.list {
			margin-bottom: 16px;
		}

		.handle {
			position: relative;
			width: 36px;
			height: 48px;
			margin-left: -36px;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url('../assets/ic_12_drag.svg');
			opacity: 0;
			transition: all .2s ease-in-out;

			&--active {
				margin-left: -12px;
				opacity: 1;
			}
		}
	}

	.delete-icon {
		opacity: .4;
	}

	/* SORTABLE FIX */
	.sortable-chosen {
		background: $colorWhite;
		border: 2px dotted rgba($colorBlack, .16);
		box-shadow: none;
		border-radius: 4px;
		transition: inherit;

		.list__item__content {
		}

		img, .list__item__label, .handle  {
			opacity: .16;
		}
	}

	.sortable-drag {
		opacity: 0;
	}
</style>